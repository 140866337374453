<template>
  <div class="page">
    <h2>搜索商品</h2>
    <chooseGoods chooseGoods @handleChange="handleChange"/>
    <h2>批量操作</h2>
    <el-form label-width="120px" label-position="left">
      <el-form-item label="定时上架">
        <el-date-picker
            v-model="startTime"
            type="datetime"
            placeholder="选择上架日期时间">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="定时下架">
        <el-date-picker
            v-model="endTime"
            type="datetime"
            placeholder="选择下架日期时间">
        </el-date-picker>
      </el-form-item>
    </el-form>
    <div class="fucView">
      <el-button type="primary" :disabled="choosedGoodsList.length == 0" @click="save">保存</el-button>
    </div>
  </div>
</template>

<script>
import chooseGoods  from "@/views/tools/components/chooseGoods";
import {apiModfiyGoodsList} from "@/request/api";

export default {
  name: "setGoodsTime",
  components: {
    chooseGoods
  },
  data() {
    return {
      startTime: '',
      endTime: '',
      choosedGoodsList: [],
    };
  },
  methods: {
    handleChange(value) {
      this.choosedGoodsList = value;
    },
    save(){
      var ids = this.choosedGoodsList.map(item => item.goods_id);
      apiModfiyGoodsList({
        on: this.startTime,
        off: this.endTime,
        ids: ids
      }).then(res => {
            this.$message.success(res.message);
      })
    }
  },
}
</script>

<style scoped>
.page {
  background-color: #FFF;
  padding: 20px;
  /*margin: 20px;*/
}
.fucView {
  display: flex;
  align-items: center;
  margin-left: 120px;
}
.el-form-item {
  margin-bottom: 10px;
}
</style>